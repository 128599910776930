<script>

export default {
    name: 'websocket-progress',
    props: [ 'wspath', 'title' ],
    created() {
        this.$store.dispatch('websocket_progress/reset', this.title);
        this.$store.dispatch('websocket_progress/set_path', this.wspath);
    },
    computed: {
        messages() { return this.$store.state.websocket_progress.messages; },
        loader()  { return this.$store.state.websocket_progress.loader; },
        variant() { return this.$store.state.websocket_progress.variant; },
    },
    watch: {
    },
    methods: {
        close() {
            this.$emit( 'closed', {} );
        },
        connection_closed() {
            this.$emit( 'connection_closed', {} );
        },
    },
    data() {
        return {
        }
    },
}

</script>

<template>
    <div class="console">
        <div v-for="message in messages" :class="message.variant">{{message.message}}</div>
    </div>
</template>
<style>
.console {
    width: 100%;
    border: 1px solid;
    background-color: #eee;
    padding: 10px;
    font-family: monospace;
    font-size: 13px;
    max-height: 300px;
    height: 300px;
    overflow: scroll;
    margin-bottom:10px;
}
.console > div.warning {
    color: #ffa500;
}
.console > div.error {
    color: #dc3545;
}
.console > div.alert {
    background-color: #dc3545;
    color: white;
    text-align: center;
    font-weight: 700;
}
</style>

