<script>

import bnvbase from './bnvbase.vue';
import Utils from '@/lib/utils.js';
const md5 = require('md5');
const moment = require('moment');

export default {
    extends: bnvbase,
    props: [ 'store', ],
    computed: {
        state() {
            // Return false (invalid) if new tag is a duplicate
            //return this.value.indexOf(this.newTag.trim()) > -1 ? false : null
            return null;
        },
        references() { return this.creator.schema.references || {}; },
        has_label() { return this.references.label === true ? true : false; },
        has_note() { return this.references.note === true ? true : false; },
        can_save() {
            //return ( this.creator.schema.references.hasOwnProperty('save_new_value') && this.creator.schema.references.save_new_value === true ) ? true : false;
            return true;
        },
        validator() {
            return 'validate_new_item_' + this.references.new_item_type;
        },
    },
    created () {
        //this.or_model = typeof this.value === 'undefined' || this.value === null ? {} : JSON.stringify( this.value );
        this.model = typeof this.value === 'undefined' || this.value === null ? {} : JSON.parse( JSON.stringify( this.value ) );
        this.signature = moment().valueOf();
    },
    methods: {
//      blur() {
//          console.log( 'original', this.or_model );
//          console.log( 'edit    ', this._model );
//          console.log( this.or_model === this._model );
//          if ( this.or_model === this.model ) { return; }
//          this.$emit( 'generic-event', { event_name: 'changed-data', payload: { name: this.name, value: this.model } } );
//      },
        resetInpuntValue() {},
        formatter( value ) {
            return value.toUpperCase()
        },
        clear_form() {
            this.new_item_state  = null;
            this.new_item        = null;
            this.new_item_label  = null;
            this.new_item_note   = null;
            this.edit_item_state = null;
            this.edit_item       = null;
            this.edit_item_label = null;
            this.edit_item_note  = null;
            this.edit_item_id    = null;
        },
        toggle_modal( modal_id = null ) {
            if ( modal_id === null ) { modal_id = 'new-item-' + this.name + '-' + this.signature; }
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
        set_focus( type ) {
            if ( type === 'new' ) {
                this.$refs.new_element.focus();
            } else if ( type === 'edit' ) {
                this.$refs.edit_element.focus();
            }
        },
        save_new_item() {
            if ( this[ this.validator ]( this.new_item ) ) {
                var new_item = { value: this.new_item };
                if ( this.has_label ) { new_item.label = this.new_item_label || null; }
                if ( this.has_note ) { new_item.note = this.new_item_note || null; }
                if ( this.model.filter( x => x.value === this.new_item ).length > 0 ) { return; }
                new_item.id = md5( JSON.stringify( new_item ) + moment().format() );
                this.model.push( new_item );
                this.toggle_modal();
            }
        },
        save_edit_item() {
            if ( this[ this.validator ]( this.edit_item ) ) {
                for ( var i = 0; i < this.model.length; i++ ) {
                    if ( this.model[ i ].id !== this.edit_item_id ) { continue; }
                    break;
                }
                if ( this.model.filter( x => x.value === this.edit_item && x.id !== this.edit_item_id ).length > 0 ) { return; }
                this.model[ i ].value = this.edit_item;
                if ( this.has_label ) { this.model[ i ].label = this.edit_item_label || null; }
                if ( this.has_note ) { this.model[ i ].note = this.edit_item_note || null; }
               this.toggle_modal('edit-item-' + this.name + '-' + this.signature);
            }
        },
        delete_edit_item() {
            for ( var i = 0; i < this.model.length; i++ ) {
                if ( this.model[ i ].id !== this.edit_item_id ) { continue; }
                break;
            }
            this.model.splice(i, 1);
            this.toggle_modal('edit-item-' + this.name + '-' + this.signature);
        },
        validate_new_item_email( data ) {
            if (Utils.isValidEmail( data ) ) {
                this.new_item_state = true;
                this.edit_item_state = true;
                return true;
            } else {
                this.new_item_state = false;
                this.edit_item_state = false;
            }
        },
        validate_new_item_string( data ) {
            this.new_item_state = true;
            this.edit_item_state = true;
            return true;
        },
        open_edit_item( id ) {
            this.edit_item_id = id;
            var item = this.model.filter( x => x.id === id )[ 0 ];
            this.edit_item = item.value;
            this.edit_item_label = item.label || '';
            this.toggle_modal('edit-item-' + this.name + '-' + this.signature);
        },
    },
    data () {
        return {
            signature       : null,
            new_item_state  : null,
            new_item        : null,
            new_item_label  : null,
            new_item_note   : null,
            edit_item_state : null,
            edit_item       : null,
            edit_item_label : null,
            edit_item_note  : null,
            edit_item_id    : null,
        }
    },
};

</script>

<template>

    <div class="form-group" :class="field_class" :style="field_style">
        <label :for="'bnv-form-' + name" :class="label_class">{{ get_label() }}</label>
        <div :class="element_container_class" class="zindex" id="'bnv-form-' + name">
            <b-row no-gutter style="padding: 0; margin: 0;">
                <b-col style="border: 1px solid #ced4da; background-color: white; padding: 1px;">
                    <b-badge pill v-for="item in model" href="#" v-b-tooltip.hover :title="item.note" @click="open_edit_item( item.id )" :key="item.id" :variant="item.note ? 'warning' : 'light'" style="margin-right: 1px">
                        <span v-if="item.label" class="label-weight">{{ item.label }}: </span>{{ item.value }}
                    </b-badge>
                </b-col>
                <b-col style="padding: 0;" cols="12" sm="auto"><b-button variant="outline-info" @click="toggle_modal()"><icon name="plus"/></b-button></b-col>
            </b-row>
        </div>

        <!-- modal new-item -->
        <b-modal
            :id         = "'new-item-' + name + '-' + signature"
            :title      = "'Nuova voce ' + get_label()"
            @hide       = "clear_form"
            button-size = "sm"
            @shown      = "set_focus('new')"
            >
            <div>
                <b-form-input ref="new_element" :state="new_item_state" v-model="new_item" placeholder="Nuova voce"></b-form-input>
                <div class="invalid-feedback">
                    nuova voce non valida
                </div>
                <div style="margin-top: 20px;">
                    <b-form-input v-if="has_label" ref="new_element_label" v-model="new_item_label" placeholder="Etichetta"></b-form-input>
                </div>
                <div style="margin-top: 20px;">
                    <b-form-textarea v-if="has_note" ref="new_element_note" v-model="new_item_note" placeholder="Scrivi una nota"></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="save_new_item">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>

        <!-- modal edit-item -->
        <b-modal
            :id="'edit-item-' + name + '-' + signature"
            :title="'Modifica voce ' + get_label()"
            @hide="clear_form"
            button-size="sm"
            @shown="set_focus('edit')"
            >
            <div>
                <b-form-input ref="edit_element" :state="edit_item_state" v-model="edit_item" placeholder="Nuova voce"></b-form-input>
                <div class="invalid-feedback">
                    nuova voce non valida
                </div>
                <div style="margin-top: 20px;">
                    <b-form-input v-if="has_label" ref="edit_element_label" v-model="edit_item_label" placeholder="Etichetta"></b-form-input>
                </div>
                <div style="margin-top: 20px;">
                    <b-form-textarea v-if="has_note" ref="edit_element_note" v-model="edit_item_note" placeholder="Scrivi una nota"></b-form-textarea>
                </div>
            </div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="save_edit_item">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-danger" @click="delete_edit_item()">
                      Elimina
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>

<style>
    .zindex {
        z-index: 20;
    }
    .label-weight {
        font-weight: 500 !important;
    }
</style>
