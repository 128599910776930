////////////////////

import base_module from '@/vue-model-mirror/stores/base.module';
import { importersService } from '@/services';

var options = {
    model_name    : 'importers_config',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
        ...base.state,
        loading             : false,
        updated             : false,
        importer_is_running : false,
};

const actions = {
        ...base.actions,
        load( { dispatch, state }, data = { options: {} } ) {
            dispatch( 'get_all', data );
        },
        get_all({ commit, state }, data = { options: {} }) {
            commit('start_request');
            importersService.getAll( data.options)
                .then( response => {
                    commit('success_list', response)
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        setimporter_is_running( { commit }, value ) {
            commit( 'setimporter_is_running', value )
        },
        run({ commit, rootGetters }, data) {
            
            console.log(" ==> Eseguo importer remoto");
            console.log(" ==> state");
            console.log(state);
            console.log(" <======");
            
            commit('start_request');
            importersService.run(data)
                .then( obj => {
                    commit('success', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        otp({ commit, rootGetters }, data) {
            commit('start_request');
            commit('clearupdate');
            importersService.otp(data)
                .then( obj => {
                    commit('successupdate', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        status({ commit, rootGetters }, data) {
            commit('start_request');
            commit('clearupdate');
            importersService.status(data)
                .then( obj => {
                    commit('successupdate', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        settings({ commit, rootGetters }, data) {
            commit('start_request');
            commit('clearupdate');
            importersService.settings(data)
                .then( obj => {
                    commit('successupdate', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
}

const mutations = {
        ...base.mutations,
        setimporter_is_running( state, value ) {
            state.setimporter_is_running = value;
        },
        successupdate( state, obj ) {
            state.obj      = obj;
            state.loading  = false;
            state.error    = false;
            state.executed = false;
            state.created = false;
            state.updated  = true;
        },
        clearupdate(state) {
            state.updated  = false;
        }
}

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters: base.getters,
};

