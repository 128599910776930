<script>

import { router } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    data() {
        return {
            role_id: this.$store.getters['authentication/customer_ids'],
            selected: '/list/pratica' // TODO
        }
    },
    computed: {
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `icons/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        app_label () {
            if ( config.gui.hasOwnProperty('app_label') && config.gui.app_label ) { return config.gui.app_label; }
            return undefined;
        },
    },
    created () {
        //this.send_presence();
    },
    methods: {
        navigate_to(route) {
            if ( route !== this.selected ) {
                this.selected = route;
                this.$router.push( route );
            }
        },
        logout() {
            router.push('/login');
        },
        send_presence() {
            //setTimeout( () => {
                this.$store.dispatch('presence/open');
            //}, 2000);
            //this.send_presence();
        }
    }
}

</script>

<template>

    <div style="margin-bottom: 15px;">
        <b-navbar toggleable="lg" type="dark" :variant="color ? '' : 'info'" fixed="top" v-bind:style="color ? 'background-color:' + color : ''">
            <!-- <b-navbar-brand href="#">
                <b-img v-if="icon_url" :src="icon_url" fluid rounded="circle" width="25" alt="customer logo" style="background-color: white; padding: 2px;"></b-img>
                {{ app_label }}
            </b-navbar-brand> -->

            <b-navbar-brand href="#" @click="navigate_to('/')">
                <b-img v-if="icon_url" :src="icon_url" fluid rounded="circle" width="25" alt="customer logo" style="background-color: white; padding: 2px;"></b-img>
                &nbsp;<span style="font-weight: 800;">PRATICHE</span>
            </b-navbar-brand>

            <b-navbar-toggle class="ml-auto" target="nav-collapse"></b-navbar-toggle>
    
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item href="#" @click="navigate_to('/appuntamento')">Appuntamenti</b-nav-item>
                    <b-nav-item href="#" @click="navigate_to('/list/ispezione')">Ispezioni</b-nav-item>
                    <b-nav-item href="#" @click="navigate_to('/stat')">Statistiche</b-nav-item>
                    <b-nav-item-dropdown text="Impostazioni">
                        <!-- <b-dropdown-item href="#" style="color: grey;" v-if="user.role_id === 1" @click="navigate_to('/list/customer')">Customer</b-dropdown-item> -->
                        <b-dropdown-item href="#" style="color: grey;" @click="navigate_to('/list/registry')">Anagrafiche</b-dropdown-item>
                        <b-dropdown-item href="#" style="color: grey;" @click="navigate_to('/list/sollecito_document')">Documenti dei solleciti</b-dropdown-item>
                        <b-dropdown-item href="#" style="color: grey;" @click="navigate_to('/list/email_template')">Template delle Email</b-dropdown-item>
                        <b-dropdown-item href="#" style="color: grey;" @click="navigate_to('/list/tipo_pratica')">Tipologie di Pratica</b-dropdown-item>
                        <b-dropdown-item href="#" style="color: grey;" v-if="user.role_id === 1" @click="navigate_to('/list/user')">Utenti</b-dropdown-item>
                        <b-dropdown-item href="#" style="color: grey;" v-if="user.role_id === 1" @click="navigate_to('/importers')">Importers</b-dropdown-item>
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item href="#" disabled>Disabled</b-nav-item> -->
                    <!-- <b-nav-item-dropdown text="Settings" right>
                        <b-dropdown-item href="#" @click="navigate_to('/list/importer_credential')">Importers</b-dropdown-item>
                    </b-nav-item-dropdown> -->
                </b-navbar-nav>
    
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#" @click="logout()">logout</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

</template>

