////////////////////

import { authHeader } from '@/helpers';

const env    = process.env.NODE_ENV || 'development';
const config = require('@/config.js')[ env ];

export const importersService = {
    getAll,
    run,
    status,
    otp,
    settings,
};

function getAll(options) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${config.backend_url}importers`, requestOptions).then(handleResponse);
}

function run( data ) {
    var url = `${config.backend_url}importers/run`;
    var options = {
        method  : 'POST',
        headers : authHeader(),
        body    : JSON.stringify( data ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function status(data) {
    var url = `${config.backend_url}importers/status`;
    var options = {
        method  : 'POST',
        headers : authHeader(),
        body    : JSON.stringify( data ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}
function settings(data) {
    var url = `${config.backend_url}importers/settings`;
    var options = {
        method  : 'POST',
        headers : authHeader(),
        body    : JSON.stringify( data ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}
function otp(data) {
    var url = `${config.backend_url}importers/otp`;
    var options = {
        method  : 'POST',
        headers : authHeader(),
        body    : JSON.stringify( data ),
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}
