////////////////////

import Vue    from 'vue';
import Router from 'vue-router';
const np_progress = require('nprogress');

import login                  from '@/login';
import reset_password         from '@/login/reset_password';
import home                   from '@/home';
import perizia                from '@/perizia';
import cameratest             from '@/cameratest';
import pratica_page           from '@/praticav2/page'
import pratica_list           from '@/praticav2/list';
import ispezione_list         from '@/ispezione/list'
import ispezione_page         from '@/ispezione/page'
import statistic              from '@/report/index'
import email_template_form    from '@/email_template/form';
import email_template_list    from '@/email_template/list';
import customer_page          from '@/pratica/sollecito/customer_page';
import registry_list          from '@/registry/list';
import registry_form          from '@/registry/form';
import registry_forms         from '@/registry/forms';
import appuntamento           from '@/appuntamento/list';
import appuntamento2          from '@/appuntamento/list2';
import importers from '@/importers/list';

import { listpage, formpage } from '@/vue-model-mirror';
import { store } from '@/store';

Vue.use(Router);

function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/login', component: login },
        { path: '/savepassword', component: reset_password },
        //{ path: '/', component: home },

        { path: '/list/pratica', component: pratica_list, props: { schema_from_route: 'pratica' } },
        { path: '/list/ispezione', component: ispezione_list, props: { schema_from_route: 'ispezione' } },
        { path: '/list/registry', component: registry_list, props: { schema_from_route: 'registry' } },
        { path: '/list/:schema', component: listpage },
        { path: '/form/pratica/:id?', component: pratica_page },
        { path: '/form/ispezione/:id?', component: ispezione_page, props: { schema_from_route: 'task' } },
        { path: '/form/email_template/:id?', component: email_template_form, props: { schema_from_route: 'email_template' } },
        { path: '/form/reminder/customer/:id?', component: customer_page },
        { path: '/form/registry/:id?', component: registry_form, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_agenzia/:id?', component: registry_forms.agenzia, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_persona_fisica/:id?', component: registry_forms.persona_fisica, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_persona_giuridica/:id?', component: registry_forms.persona_giuridica, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_compagnia/:id?', component: registry_forms.compagnia, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_ispettorato/:id?', component: registry_forms.ispettorato, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_liquidatore/:id?', component: registry_forms.liquidatore, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_ente_pubblico/:id?', component: registry_forms.ente_pubblico, props: { schema_from_route: 'registry' } },
        { path: '/form/registry_polizza/:id?', component: registry_forms.polizza, props: { schema_from_route: 'registry' } },
        { path: '/form/:schema/:id?', component: formpage },
        { path: '/perizia/:id?', component: perizia },
        { path: '/stat', component: statistic, props: { schema_from_route: 'search_statistic' } },
        { path: '/appuntamento/:id?', component: appuntamento, props: { schema_from_route: 'appuntamento' } },
        { path: '/appuntamento2/:id?', component: appuntamento2, props: { schema_from_route: 'appuntamento' } },

        // Gestione importer
        { path: '/importers', component: importers, props: { schema_from_route: 'importers_config' } },
        
        
        // otherwise redirect to home
        { path: '*', redirect: '/list/pratica' }
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

router.beforeResolve( async (to, from, next) => {
    if ( to.path === '/list/ispezione' ) {
        // la lista delle ispezioni viene ricaricata ad ogni accesso a /list/ispezione
        store.dispatch('ispezione/force_reload');
    }
    np_progress.start()
    await sleep(100);
    next()
});

router.afterEach((to, from, next) => {
    if ( from.path.match(/^\/form\/ispezione\/\d+/) ) {
        store.dispatch('task/close_connection', []);
    }
});

router.beforeEach((to, from, next) => {
    var is_logged = store.getters['authentication/is_logged'];
    var user      = store.getters['authentication/user'];
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = [ '/login', '/savepassword', '/resetpassword' ];
    var authRequired = true;
    if ( publicPages.includes(to.path) || to.path.startsWith('/form/reminder/customer') ) {
        authRequired = false;
    }
    if (authRequired && !is_logged) {
        return next('/login');
    } else if ( is_logged ) {
        // se sto ricaricando la pagina e l'utente non è settato in statemanager
        if ( store.getters['statemanager/is_setted'] === false ) { store.dispatch( 'statemanager/set_obj', JSON.parse( localStorage.getItem('user') ) ); }
        // se sto ricaricando la pagina e user_letter non è stato popolato
        store.dispatch('userletter/get_all');
        // se sto ricaricando la pagina e registry_templates non è stato popolato
        store.dispatch('registry_template/get_all');
        // se sto ricaricando la pagina e registry_roles non è stato popolato
        store.dispatch('registry_role/get_all');
        if ( user.role_id !== 1 && ( /^\/list\/user/.test( to.path ) || /^\/list\/customer/.test( to.path ) ) ) {
            return next('/list/pratica');
        }
    }

    next();
})

