////////////////////

import axios from 'axios';
const moment = require('moment');
import base_module from '@/vue-model-mirror/stores/base.module';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

var options = {
    model_name    : 'pratica',
    send_alert    : false,
    default_order : 'codice_pratica ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters        : {
        show_mine             : true,
        show_map              : false,
        no_luogo_appuntamento : false,
        // Filtri aggiuntivi
        primo_contatto_incompleto : false,
        appuntamento_done : false,
        ispezione_done : false,
        mostra_assegnati : false,
        date_filters      : [], // [ { field: '', range: null } ],
    },
    title_form     : 'Tipologie di Pratica',
    items          : null,
    tot            : 0,
    page           : 1,
    task_overrided : false,
    ricerca_id     : '',
    edit_mode      : false,
    regione_id     : '',
    provincia_id   : '',
    comune_id      : '',
    // advanced_search
    text_filter       : '',
    text_filter_valid : true,
    //
};

const actions = {
    ...base.actions,
    set_ricerca_id( { commit, dispatch }, value ) { commit('set_ricerca_id', value); dispatch('ricerca/set_active_obj', value, { root: true }); },
    set_edit_mode( { commit }, value ) { commit('set_edit_mode', value); },
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        //data.options.contains = 'unipol'; // TODO test da rimuovere
        var userjson = localStorage.getItem('user');
        var userobj = JSON.parse( userjson );
        let customer_ids = userobj.my_customers.map( x => x.id );
        data.options.customer_id__in = customer_ids.join(',');
        Object.keys( state.filters ).map( key => {
            if ( key === 'date_filters' ) {
                let date_filters = state.filters.date_filters;
                for ( var i = 0; i < state.filters.date_filters.length; i++ ) {
                    if (date_filters[ i ].range == null) continue;
                    data.options[ date_filters[ i ].field ] = [
                        moment( date_filters[ i ].range[0] ).format('YYYY-MM-DD'),
                        moment( date_filters[ i ].range[1] ).format('YYYY-MM-DD'),
                    ].join('|');
                }
            } else {
                data.options[ key ] = state.filters[ key ];
            } 
        });
        let url = config.backend_url + 'model/pratica/appuntamento';
        let params_key = Object.keys( data.options );
        if ( params_key.length > 0 ) {
            url += '?';
            params_key.map( key => { url += `${ key }=${ data.options[ key ] }&`; });
        }
        let options = { headers: authHeader() };
        axios.get( url, options )
            .then( res => {
                let response = res.data;
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    commit('set_page', response.page);
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response.data)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    override_task( {commit}, task ) { commit('overridetask', task); },
    override_incarico( {commit}, incarico ) { commit('overrideincarico', incarico); },
    save_filter_value( { commit }, data ) { commit( 'save_filter_value', data ); },
    remove_filter_value( { commit }, key ) { commit( 'remove_filter_value', key ); },
    // newregistry
    set_text_filter( { commit }, value ) { commit( 'set_text_filter', value ); },
    // date filters
    set_date_filters( { commit }, value ) { commit( 'set_date_filters', value ); },
};

const mutations = {
    ...base.mutations,
    save_filter_value( state, data ) {
        let key = Object.keys( data )[0];
        state[ key ] = data[ key ];
    },
    remove_filter_value( state, key ) {
        state[ key ] = '';
    },
    set_ricerca_id( state, value ) { state.ricerca_id = value; },
    set_edit_mode( state, value ) { state.edit_mode = value; },
    success_list( state, items ) {
        state.items    = items.map( x => { x.selected = false; return x; } );
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
    },
    overridetask(state, task) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice !== task.codice_pratica ) { continue; }
                for ( var x = 0; x < state.items[ i ].tasks.length; x++ ) {
                    if ( state.items[ i ].tasks[ x ].id !== task.id ) { continue; }
                    state.items[ i ].tasks[ x ] = task;
                    break;
                }
            }
        }
        state.task_overrided = true;
        setTimeout( () => { state.task_overrided = false; }, 250 );
        var found = false;
    },
    overrideincarico(state, incarico) {
        if ( state.items ) {
            for ( var i = 0; i < state.items.length; i++ ) {
                if ( state.items[ i ].codice !== incarico.codice_pratica ) { continue; }
                state.items[ i ].pregs.incarico = incarico;
                break;
            }
        }
    },
    // newregistry
    set_text_filter( state, value ) { state.text_filter = value; },
    // date filters
    set_date_filters( state, value ) {
        state.filters.date_filters = value; 
    },
};
const getters = {
    ...base.getters,
    ricerca_id: state => state.ricerca_id,
}


export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

