/**
 * Utility Varie
 */
class Utils {
    
        /**
         * Validazione di un indirizzo email
         */
        static isValidEmail(email) {
            const emailRegex = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
            return emailRegex.test(email);
        }
}
module.exports = Utils;