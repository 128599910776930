///////////////////////////////////////

// GESTORE PROGRESS ELABORAZIONE DA WEBSOCKET

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ];

export const websocket_progress = {
    namespaced: true,
    state: {
        messages          : [],
        ws               : null,
        wspath           : null,
        otp_required : false,
        completed : false,
    },
    actions: {
        set_path( { commit, state }, wspath ) {
            commit('set_path', wspath );
            if ( config.backend_url.includes( 'https' ) ) {
                var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + state.wspath );
            } else if ( config.backend_url.includes( 'http' ) ) {
                var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + state.wspath );
            }
            commit('set_ws', ws );
            ws.onmessage = msg => commit('in_message', msg);
        },
        reset( { commit }, message_class ) { commit('reset', message_class); },
    },
    mutations: {
        set_path( state, wspath ) { state.wspath = wspath; },
        set_ws( state, ws ) { state.ws = ws; },
        in_message( state, msg ) {
            var message = JSON.parse(msg.data);
            
            // Riformatto l'output per uniformare e semplificare
            var msg = {
                    variant: message.level,
                    message : message.action,
            }
            
            // Verifichiamo se c'e' la segnalazione della richiesta di otp
            if (message.otp) state.otp_required = true; 
            // Verifichiamo se l'esecuzione è terminata
            if (message.completed) state.completed = true;
            
            state.messages.unshift(msg);
        },
        reset( state, message_class ) {
            state.messages        = [{ 'variant': 'info', message: 'Inizializzazione in corso...' }];
            state.otp_required = false;
            state.completed = false;
        },
    }
}
